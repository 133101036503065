<template>
	<div>
		<div>
			<v-container>
			<v-breadcrumbs :items="breadcrumbs" class="px-0 pt-2 pb-0">
				<template v-slot:divider>
					<v-icon>mdi-chevron-right</v-icon>
				</template>
			</v-breadcrumbs>
		</v-container>
		</div>
		<div class="dashboard">
		<div class="dashboard-body">
			<!-- <h2 class="primary--text text-center">Legal</h2> -->
			<div class="boxes">
				<template v-for="(item, index) in items">
					<router-link
						v-if="
							checkModule({
								moduleName: item.moduleName,
								pageName: item.pageName,
							})
						"
						:to="{ name: item.to }"
						:key="index + '_route'"
					>
						<div class="text" :style="{ position: 'relative' }">
							<v-icon>{{ item.icon }}</v-icon>
							<div class="text__title" v-html="item.title"></div>
							<div
								class="text__description"
								v-text="item.description"
							></div>
						</div>
					</router-link>
				</template>
			</div>
		</div>
	</div>
	</div>
</template>
<script>

export default {
	name: 'IncidentHomePage',
	data() {
		return {
			items: [
				{
					to: 'IncidentList',
					title: 'Lista y Registro <br> de Tickets',
					description: '',
					moduleName: 'reportesFalla',
					pageName: 'listaIncidentes',
					icon: 'mdi-sticker-plus-outline',
				},
				{
					to: 'RequestList',
					title: 'Tickets <br> por Atender',
					description: '',
					moduleName: 'solicitudes',
					pageName: 'listaSolicitudes',
					icon: 'mdi-sticker-text-outline',
				},
			],
		};
	},
	created() {
	},
	computed: {
		breadcrumbs() {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Incidentes',
					link: true,
					exact: true,
					disabled: true,
					// to: {
					// 	name: 'InventoryHome',
					// },
				},
				
			];
		},
	},
	methods: {
		checkModule({ moduleName = '', pageName = '' }) {
			let found = false;
			const activities = this.$store.state.auth.activities || [];
			if (Array.isArray(activities)) {
				found = activities.some((activity) => {
					return (
						activity.module_name == moduleName &&
						activity.page_name == pageName
					);
				});
			}
			return found;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/dashboardHome.scss';
</style>
<style lang="scss">
.amountNotify {
	position: absolute;
	right: 0;
	top: 0;
	transform: translate(-50%, -70%);
}
</style>
